<template>
  <v-container class="px-10" fluid>
    <!-- Breadcrumbs -->

    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <h1 class="headline">Coupon Reports</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Customer Name, Mobile No, Coupon Title"
          single-line
          hide-details
          clearable
          @input="requestSearchCouponList()"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>

    <template>
      <v-container>
        <v-layout row wrap>
          <v-flex>
            <v-chip-group active-class="blue darken-2--text text--white">
              <v-chip
                v-for="tag in tags"
                :key="tag"
                @click="filterClick(tag)"
                >{{ tag }}</v-chip
              >
            </v-chip-group>
          </v-flex>
          <v-flex v-show="isShowCustom">
            <v-col cols="0" sm="0" md="0">
              <v-menu
                ref="startDateIsShow"
                v-model="startDateIsShow"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{on}">
                  <v-text-field
                    v-model="dateRangeText"
                    label="Start date ~ end date"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="rangedate" range no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="startDateIsShow = false"
                    >Cancel</v-btn
                  >
                  <v-btn text color="primary" @click="applyRangeDate(rangedate)"
                    >Apply</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-flex>
        </v-layout>
      </v-container>
    </template>

    <v-card>
      <v-layout fluid v-resize="onResize">
        <v-data-table
          :headers="headers"
          :items="dataList"
          :search="search"
          :options.sync="options"
          :server-items-length="totaluser"
          :no-results-text="noResultText()"
          :no-data-text="noResultText()"
          :loading="loading"
          :footer-props="{'items-per-page-options': perpage}"
          class="elevation-1"
          fixed-header
          style="width: 100%"
          :height="windowSize.y - heightOptions.header - heightOptions.tableFooter"
        >
          <template v-slot:item.redeem_date="{item}">{{
            convertDate(item.redeem_date)
          }}</template>
          <template v-slot:item.firstName="{item}">{{
            `${item.firstName} ${item.lastName}`
          }}</template>
        </v-data-table>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions} from 'vuex'
import {
  convertDate,
  convertSelectedDate,
  searchHandler
} from '../../helper/helper'
import moment from 'moment'

export default {
  name: 'home',
  computed: {
    dateRangeText() {
      let date = ''
      if (this.rangedate.length > 1) {
        if (moment(this.rangedate[0]).isBefore(moment(this.rangedate[1]))) {
          date = `${convertSelectedDate(
            this.rangedate[0]
          )} ~ ${convertSelectedDate(this.rangedate[1])}`
        } else {
          date = `${convertSelectedDate(
            this.rangedate[1]
          )} ~ ${convertSelectedDate(this.rangedate[0])}`
        }
        return date
      } else {
        date = convertSelectedDate(this.rangedate[0])
        return date
      }
    }
  },
  methods: {
    onResize () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    applyRangeDate(date) {
      if (date.length === 1) {
        this.startdate = date[0]
        this.enddate = date[0]
      } else {
        if (moment(date[0]).isBefore(moment(date[1]))) {
          this.startdate = date[0]
          this.enddate = date[1]
        } else {
          this.startdate = date[1]
          this.enddate = date[0]
        }
      }
      this.requestReportCouponList(1)
      this.startDateIsShow = false
    },
    filterClick(value) {
      if (value === 'Custom' && this.filterString !== 'Custom') {
        this.isShowCustom = true
      } else {
        this.isShowCustom = false
      }

      this.filterString = value === this.filterString ? '' : value
      this.requestReportCouponList(1)
    },
    noResultText() {
      return `Search Not Found "${this.search}"`
    },
    // handlerClick(value) {
    //     // For use in future
    //      this.$router.push({name: 'user-detail', params: {uid: value.uid}})
    // },
    convertDate(date) {
      return convertDate(date)
    },
    requestSearchCouponList: async function () {
      this.timer = searchHandler(this.timer, clearTimeout, () => {
        this.requestReportCouponList(1)
      })
    },
    async requestReportCouponList(page) {
      this.loading = true
      const payload = {
        page: page,
        search:
          this.search === null ? '' : `${encodeURIComponent(this.search)}`,
        limit: this.itemsPerPage,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
        start_date: this.startdate,
        end_date: this.enddate,
        filter: this.filterString
      }
      let data = await this.fetchDataCouponReport(payload)

      if (data !== false) {
        this.dataList = data.data.data
        this.totaluser = data.data.total
        this.loading = false
      }
    },
    ...mapActions('report', ['fetchDataCouponReport'])
  },
  watch: {
    options: {
      handler() {
        const {sortBy, sortDesc, page, itemsPerPage} = this.options
        this.itemsPerPage = itemsPerPage
        this.sortBy = sortBy
        this.sortDesc = sortDesc
        this.requestReportCouponList(page)
      },
      deep: true
    },
    search: {
      handler() {
        this.options.page = 1
      }
    },
    filterString: {
      handler() {
        this.options.page = 1
      }
    },
    startdate: {
      handler() {
        this.options.page = 1
      }
    },
    enddate: {
      handler() {
        this.options.page = 1
      }
    }
  },
  data() {
    return {
      heightOptions: {
        header: 352,
        tableFooter: 59,
      },
      windowSize: {
        x: 0,
        y: 0,
      },
      search: '',
      tags: ['Daily', 'Weekly', 'Monthly', 'Yearly', 'Custom'],
      isShowCustom: false,
      itemsPerPage: 25,
      totaluser: 0,
      sortBy: '',
      sortDesc: false,
      loading: true,
      options: {},
      perpage: [25, 50, 100],
      headers: [
        {text: 'Transaction ID', value: 'id'},
        {text: 'Coupon Name', value: 'title'},
        {text: 'Coupon Value', value: 'coupon_value'},
        {text: 'Customer', value: 'firstName'},
        {text: 'Mobile No.', value: 'phone_number'},
        {text: 'Redeemed On', value: 'redeem_date'}
      ],
      dataList: [],
      filterString: '',
      breadcrumbs: [
        {
          text: 'Dashboard',
          exact: false,
          href: '/'
        },
        {
          text: 'Reports',
          disabled: true,
          link: true,
          to: '/'
        },
        {
          text: 'Coupon Reports',
          disabled: true,
          link: true,
          to: '/'
        }
      ],
      rangedate: [],
      startdate: new Date().toISOString().substr(0, 10),
      enddate: new Date().toISOString().substr(0, 10),
      startDateIsShow: false,
      endDateIsShow: false,
      modal: false,
      menu2: false
    }
  }
}
</script>
<style scoped>
.row td {
  cursor: pointer;
}

/* also tried table.v-table thead tr th { . */
table.v-table tbody tr td {
  font-size: 16px;
  height: 15px;
  padding: 0px;
}
</style>
